import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, } from 'react-router-dom';
import './App.scss';
import { Layout } from './features/layout/Layout';
import { Classes } from './features/classes/Classes';
import { Home } from './features/home/Home';
import { IFrame } from './features/iframe/IFrame';

export const App = () =>
(
  <Router>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Navigate to="/home"></Navigate>}></Route>
        <Route path="classes" element={<Classes></Classes>} ></Route>
        <Route path="iframe" element={<IFrame></IFrame>} ></Route>
        <Route path="home" element={<Home></Home>} ></Route>
      </Route>
    </Routes>
  </Router>
)

export default App;
