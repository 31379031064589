import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { CardGroup } from "react-bootstrap";


export const Home = ()=>{

    return (
        <>
            
                {/*

                    NOTES

                */}   


            <div>
                <div style={{textAlign: "center"}}>
                    <h1 style={{padding: "2.5rem", fontWeight: "lighter", letterSpacing: '.2em'}}>NOVA HOME</h1>
                </div>       
                    <CardGroup>

                        <Card id="Classes" className="card-background-classes" style={{ width: '18rem', textAlign: 'center'  }}>
                            <Card.Body>
                                <Card.Title>Classes</Card.Title>
                                <Card.Text>
                                The ACES sales team replacement for Desktop Assistant
                                </Card.Text>
                                <a href="./classes/"><Button variant="primary">Go to Classes</Button></a>
                            </Card.Body>
                        </Card>

                        <Card id="Sales" className="card-background-sales" style={{ width: '18rem', textAlign: 'center'  }}>
                            <Card.Body>
                                <Card.Title>Client Lookup</Card.Title>
                                <Card.Text>
                                Sales team resources including classes search and client lookup
                                </Card.Text>
                                <a href="/iframe?url=Clients/ShowClientsTablePage.aspx"><Button variant="primary">Client Lookup</Button></a>
                            </Card.Body>
                        </Card>

                        <Card  id="SalesPortal" className="card-background-sales-portal" style={{ width: '18rem', textAlign: 'center'  }}>
                            <Card.Body>
                                <Card.Title>Examprep Sales Portal</Card.Title>
                                <Card.Text>
                                Website portal for placing and managing client orders
                                </Card.Text>
                                <a href="https://examprep.org/wp-login.php"><Button variant="primary">Sales Portal</Button></a>
                            </Card.Body>
                        </Card>

                        <Card id="Shipping" className="card-background-shipping" style={{ width: '18rem', textAlign: 'center'  }}>
                            <Card.Body>
                                <Card.Title>Shipping</Card.Title>
                                <Card.Text>
                                Shipping order information and related resources
                                </Card.Text>
                                <a href="/iframe?url=Reports%2FShippingDeptReport.aspx"><Button variant="primary">Go to Shipping</Button></a>
                            </Card.Body>
                        </Card>

                    </CardGroup>

                    <div id="Additional-Features">
                        <br />

                    <CardGroup>

                        <Card id="Instructors" className="card-background-instructors" style={{ width: '18rem', textAlign: 'center'  }}>
                            <Card.Body>
                                <Card.Title>Instructors</Card.Title>
                                <Card.Text>
                                View instructor schedules and student roster information
                                </Card.Text>
                                <a href="/iframe?url=SchedulePages%2FInstructorScheduleViewer.aspx"><Button variant="primary">Go to Instructors</Button></a>
                            </Card.Body>
                        </Card>

                        <Card  id="Reports" className="card-background-reports" style={{ width: '18rem', textAlign: 'center'  }}>
                            <Card.Body>
                                <Card.Title>Reports</Card.Title>
                                <Card.Text>
                                View charts and sales reports through NOVA platform
                                </Card.Text>
                                <a href="/iframe?url=Charts/ChartViewerA.aspx"><Button variant="primary">View Reports</Button></a>
                            </Card.Body>
                        </Card>

                        <Card  id="Reports" className="card-background-classes" style={{ width: '18rem', textAlign: 'center'  }}>
                            <Card.Body>
                                <Card.Title>Support</Card.Title>
                                <Card.Text>
                                Support tickets and feature requests for NOVA platform
                                </Card.Text>
                                <a href="https://examprep.hipporello.net/desk/space/643043d53e40bd70a58b17fc"><Button variant="primary">View Support</Button></a>
                            </Card.Body>
                        </Card>


                        <Card style={{ width: '18rem', textAlign: 'center' }}>
                            <Card.Body>
                                <Card.Title>Content Development</Card.Title>
                                <Card.Text>
                                Section for future content development on NOVA platform
                                </Card.Text>
                                <Button variant="secondary">Content Development</Button>
                            </Card.Body>
                        </Card>

                    </CardGroup>
                </div> 
            </div>
        </>
    )
}