import { createSlice } from "@reduxjs/toolkit";
import { LayoutState } from "./layout-state";

const initialState: LayoutState = {
    menu: [

        { title: 'Sales', to: '/iframe?url=Timeclock/ViewEmployeeTimeLog.aspx', items: <any>[
            { title: 'Classes', to: '/classes'},
            { title: 'Client Lookup', to: '/iframe?url=Clients/ShowClientsTablePage.aspx'},
            { title: 'Examprep Sales Portal', to: 'https://examprep.org/wp-login.php'},
        ]},
        { title: 'Accounting', to: '/iframe?url=Timeclock/ViewEmployeeTimeLog.aspx', items: <any>[
            { title: 'Transaction Reports', to: '/iframe?url=Reports/TransactionReportMenu.aspx'},
            { title: 'Clock In', to: '/iframe?url=Timeclock/ClockInOut.aspx'},
            { title: 'Time Logs', to: '/iframe?url=Timeclock/ViewEmployeeTimeLog.aspx'}
        ]},
        { title: 'Reports', to: '/iframe?url=Timeclock/ViewEmployeeTimeLog.aspx', items: <any>[
            { title: 'Sales Charts', to: '/iframe?url=Charts/ChartViewerA.aspx'},
            { title: 'Transaction Reports', to: '/iframe?url=Reports/TransactionReportMenu.aspx'},
        ]},
        { title: 'Scheduling', to: '/iframe?url=Timeclock/ViewEmployeeTimeLog.aspx', items: <any>[
            { title: 'Future Scheduling Feature', to: '/home'},
        ]},
        { title: 'Admin', to: '/iframe?url=Timeclock/ViewEmployeeTimeLog.aspx', items: <any>[
            { title: 'Future Administration Feature', to: '/home'},
        ]}
    ]
}

export const layoutSlice = createSlice({
    name: 'layout',
    initialState, 
    reducers: {

    }
});

export default layoutSlice.reducer;