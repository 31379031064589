import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import React, { Component } from "react";
import Select from "react-select";
import courses from "../../json-data/courses.json";
import stateNames from "../../json-data/states.json";
import parse from "html-react-parser";
import { alignPropType } from "react-bootstrap/esm/types";
import axios from "axios";
import swal from "sweetalert";
import Bundles from "../../json-data/bundles.json";
import Books from "../../json-data/books.json";
import Courses from "../../json-data/courses.json";

// user info section
import { Form } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FormControl } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { Accordion } from "react-bootstrap";

// maybe we dont need and can remove html-react-parser? I added to packages
// installed because dangerouslySetInnerHTML caused errors with Typescript

export const Classes = () => {


	// TESTUSER login functionality
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loggedIn, setLoggedIn] = useState(false);
	const [loginError, setLoginError] = useState(false);
	
	const users = [
		{
		  username: 'testuser',
		  password: 'testpass',
		},
		{
		  username: 'bobby',
		  password: 'examprep',
		},
		{
		  username: 'test3',
		  password: 'testpass1',
		},
	  ];
	
	  const handleLogin = () => {
		const validUser = users.some(
		  (user) => user.username === username && user.password === password
		);
	  
		if (validUser) {
		  setLoggedIn(true);
		  setLoginError(false);
		} else {
		  setLoginError(true);
		}
	  };
	
	const handleLogout = () => {
	  setUsername('');
	  setPassword('');
	  setLoggedIn(false);
	};
	
    // END TESTUSER login functionality


	const [coursesToDisplay, setCoursesToDisplay] = useState<any[]>(courses);
	const [selectedState, setSelectedState] = useState<any>(null);
	const [selectedClass, setSelectedClass] = useState<any>(null);

	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [date, setDate] = useState("");
	const [notes, setNotes] = useState("");
	const [booksArr, setBooksArr] = useState<any[]>();
	const [courseVariations, setCourseVariations] = useState<any[]>();




	const [activeAccordion, setActiveAccordion] = useState<string>("-1");

	useEffect(() => {
		if (selectedClass) {
			setActiveAccordion("0");
		}
	}, [selectedClass]);




	useEffect(() => {
		if (selectedState) {
			let t = courses?.filter(
				(k: any) =>
					k.state &&
					k.state.toLowerCase() === selectedState.name.toLowerCase()
			);
			setCoursesToDisplay(t);
		}
	}, [selectedState]);

	useEffect(() => {
		if (selectedClass) {
			let bundle = Bundles?.filter(
				(k: any) => k.course_id === selectedClass.id
			);

			// let arr: any[];
			// let arr : Array<any>;
			// var arr:Array = [];
			const arr = new Array<any>();

			bundle.forEach(elem => {
				elem.books_ids.forEach(bookId => {
					let w = Books?.find((k: any) => k.id === bookId);
					if (w !== undefined) arr?.push(w);
				});
			});
			setBooksArr(arr);
		}
	}, [selectedClass]);

	useEffect(() => {
		if (selectedClass) {
			let course = Courses?.find((k: any) => k.id === selectedClass.id);
			// let arr: any[];
			let arr: any[] = [];
			course?.variations?.forEach((elem: any, idx: any) => {
				let k = `${elem.date} - ${elem.event_text} -  
					${elem.name} - ${elem.address}, ${elem.city}, ${elem.state}, 
					${elem.zip} - ${elem.phone}`;
				arr?.push(k);
			});
			setCourseVariations(arr);
		}
	}, [selectedClass]);

	const submitToCRM = () => {
		const BOARD_ID = 2941867723;
		const API_TOKEN =
			"eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjE3MDQ2MzcyMiwidWlkIjozMjA5Njg2NCwiaWFkIjoiMjAyMi0wNy0xNFQxODo1NjoxNi45MDRaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTI3ODcxMjEsInJnbiI6InVzZTEifQ.HCkFeoGdBXC_V_KqwtcvlL6WXRxgZMK_OIimv25c76Q";

		let query5 =
			"mutation ($myItemName: String!, $columnVals: JSON!) { create_item (board_id:2941867723, item_name:$myItemName, column_values:$columnVals) { id } }";
		let vars = {
			myItemName: `${name}`,
			columnVals: JSON.stringify({
				name: `${name}`,
				status: { label: "Hot" },
				text7: `${phone}`,
				text9: `${email}`,
				date4: { date: `${date}`, time: "00:00:00" },
				long_text: `${notes}`
			})
		};

		fetch("https://api.monday.com/v2", {
			method: "post",
			headers: {
				"Content-Type": "application/json",
				Authorization: API_TOKEN
			},
			body: JSON.stringify({
				query: query5,
				variables: vars
			})
		})
			.then(res => res.json())
			.then(res => {
				console.log(JSON.stringify(res, null, 2));
				alert(`Data saved in CRM.`);
				setName("");
				setPhone("");
				setEmail("");
				setDate("");
				setNotes("");
			});
	};

	function copyToEmail() {
		let k: any[] = [];

		booksArr?.forEach(book => {
			k?.push(book?.name);
		});
		let booksNames = k?.join(",\n");

		let scedule = courseVariations?.join(",\n");

		let dummy = document.createElement("input");
		let text = `Dear ${name}; \n\n Here is the information you requested: \n Schedule: \n ${scedule} \n Books Requested: \n ${booksNames}`;
		document.body.appendChild(dummy);
		dummy.value = text;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
		swal("Copied to clipboard!");
	}

	return (
		<>
			<div>

				{/*  
				if there is no Monday API key for user
				hide the CustomerSection div tag
				CustomerSection only shows for users with API key
				example:
				<div id="CustomerSection" className={'d-none'}>
				*/}
				<div id="CRMLoginSection">

					{!loggedIn ? (
						<>
						<div style={{ paddingBottom: 20 }}>
							<h4 style={{ paddingTop: 20, marginBottom: 7 }}>CRM Login</h4>
								<input
									type="text"
									placeholder="Username"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
								/>
								<input
									type="password"
									placeholder="Password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<button onClick={handleLogin}>Login</button>
								{loginError && (
									<p style={{ color: 'red' }}>Incorrect username or password.</p>
								)}
						</div>
						</>
					) : (
						<>
						<p style={{ textAlign: 'right' }}>Logged in as <b>{username}</b> &nbsp;&nbsp;&nbsp; <button onClick={handleLogout}>Logout</button></p>
						
						</>
					)}
					</div>



				<div id="CustomerSection" style={{ display: loggedIn ? 'block' : 'none' }}>
					<h4 style={{ paddingTop: 20, marginBottom: -8 }}>Customer</h4>
					<Form>
						<Row>
							<Col>
								&nbsp;
								<Form.Control
									placeholder="Name"
									value={name}
									onChange={e => setName(e.target.value)}
								/>
							</Col>
							<Col>
								&nbsp;
								<Form.Control
									placeholder="Phone"
									value={phone}
									onChange={e => setPhone(e.target.value)}
								/>
							</Col>
							<Col>
								&nbsp;
								<Form.Control
									placeholder="Email"
									value={email}
									onChange={e => setEmail(e.target.value)}
								/>
							</Col>
							<Col>
								Follow Up Date
								<Form.Control
									type="date"
									value={date}
									onChange={e => setDate(e.target.value)}
								/>
								&nbsp;
							</Col>
						</Row>
					</Form>

					<InputGroup className="mb-3">
						<FormControl
							placeholder="Additional Notes"
							aria-label="Notes"
							aria-describedby="basic-addon2"
							as="textarea"
							value={notes}
							onChange={e => setNotes(e.target.value)}
						/>
						<Button
							variant="outline-primary"
							id="button-addon2"
							onClick={submitToCRM}
						>
							Submit to CRM
						</Button>
						<Button
							variant="outline-primary"
							id="button-addon2"
							onClick={copyToEmail}
						>
							Copy to Email
						</Button>
					</InputGroup>

				<hr style={{ height: 15, color: "grey" }} />

				</div>


				<h4>Course</h4>

				<div id="classes-table">
					<table>
						<tr>
							<td style={{ width: "300px" }}>
								<Select
									placeholder="State"
									options={stateNames}
									getOptionLabel={stateNames =>
										stateNames.name
									}
									getOptionValue={stateNames =>
										stateNames.abbreviation
									}
									onChange={setSelectedState}
								/>
							</td>
							<td style={{ width: "20px" }}></td>
							<td style={{ width: "850px" }}>
								<Select
									placeholder="Choose or Search Course"
									options={coursesToDisplay}
									getOptionLabel={courses => courses.name}
									getOptionValue={courses => courses.id}
									onChange={setSelectedClass}
								/>
							</td>
						</tr>
						<tr>&nbsp;</tr>
					</table>
				</div>
			</div>
			{selectedClass && (
				<div id="classes-component" style={{ marginTop: 1 }}>
					<h3 id="state-and-course"><b>
						{selectedClass.state} &nbsp; - &nbsp;{" "}
						{selectedClass.name}
						</b></h3>
				</div>
			)}
			<Accordion defaultActiveKey="0">
				<Accordion.Item eventKey="0">
					<Accordion.Header><h5>Course Schedule</h5></Accordion.Header>
					<Accordion.Body>
						<div>
							{courseVariations?.map((elem, idx) => {
								return <p key={idx}>{elem}</p>;
							})}
						</div>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<Accordion defaultActiveKey="0">
				<Accordion.Item eventKey="0">
					<Accordion.Header><h5>Required Bundles</h5></Accordion.Header>
					<Accordion.Body>
						<div>
							{booksArr?.map((elem, idx) => {
								return (
									<p key={idx}>
										{idx + 1}: {elem?.name}
									</p>
								);
							})}
						</div>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<Accordion defaultActiveKey="0">
				<Accordion.Item eventKey="0">
					<Accordion.Header><h5>Details</h5></Accordion.Header>
					<Accordion.Body>
						{selectedClass && (
							<div
								id="classes-component"
								style={{ marginTop: 20 }}
							>
								<h3>Requirements</h3>
								<p>
									{parse(
										JSON.parse(
											JSON.stringify(
												selectedClass.requirements
											)
										)
									)}
								</p>
								<h3>Services</h3>
								<p>
									{parse(
										JSON.parse(
											JSON.stringify(
												selectedClass.services
											)
										)
									)}
								</p>
								<h3>Applications</h3>
								<p>
									{parse(
										JSON.parse(
											JSON.stringify(
												selectedClass.applications
											)
										)
									)}
								</p>
								<h3>Reciprocity</h3>
								<p>
									{parse(
										JSON.parse(
											JSON.stringify(
												selectedClass.reciprocity
											)
										)
									)}
								</p>
								<h3>Continuing Education</h3>
								<p>
									{parse(
										JSON.parse(
											JSON.stringify(
												selectedClass.continuing_education
											)
										)
									)}
								</p>
								<h3>Exam</h3>
								<p>
									{parse(
										JSON.parse(
											JSON.stringify(selectedClass.exam)
										)
									)}
								</p>
							</div>
						)}
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<br />
			&nbsp;
			<br />
			<br />
			{/* can send REST API REQUEST
            
            gets all states and associated categories
            limited to 100 returned requeses, need page logic

            curl --location --request GET 'https://staging22.examprep.org/wp-json/wc/v3/products/categories' \
            --header 'Authorization: Basic Y2tfZDRkNGM1MDkzMjQzMGQ2MzJkNDcyYTczYTIzN2Q3ZjVjNzkzMzIyMTpjc19jOGQ5NmFlOWM3MzIwYmZmMDY4MGVmZDgyNTBlN2FiZTY0ZDE0NTI5'
            
            */}
		</>
	);
};
