import { useLocation } from "react-router";
import styled from "styled-components";

const Page = styled.iframe`
    height: 100%;
    widthL: 100%;
`

export const IFrame = ()=>{
    let url = new URLSearchParams(useLocation().search).get('url') ?? undefined;
    url = "https://nova2.examprep.org/" + url;
    return <Page src={url} height="100%" width="100%" ></Page>
}
