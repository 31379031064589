import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Outlet } from "react-router";
import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import { selectMenu } from "./layout.selectors";
import { useLocation } from "react-router";

const Logo = styled.img`
    height: 2rem;
`;

const LayoutContainer = styled(Container)`
    height: calc(100% - 5rem);
`

export const Layout = (props: any) => {
    let location = useLocation();
    let menu = useAppSelector(selectMenu);

    return <div className="h-100">
        <Navbar bg="light" expand="sm">
            <Container>
                <LinkContainer to="/">
                    <Navbar.Brand><Logo src="/logo.png"></Logo></Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle></Navbar.Toggle>
                <Navbar.Collapse>
                    <Nav activeKey={location.pathname + location.search}>
                        <Navbar.Text>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
                        </Navbar.Text>
                        <Nav.Link href="/classes">Classes</Nav.Link>
                        <Nav.Link href="/iframe?url=Reports%2FShippingDeptReport.aspx">Shipping</Nav.Link>
                        <Nav.Link href="/iframe?url=SchedulePages%2FInstructorScheduleViewer.aspx">Instructors</Nav.Link>
                        <Navbar.Text>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
                        </Navbar.Text>

                        {menu.map((m, i) => <Nav.Item key={i}>
                            <>
                                {
                                    m.items.length > 0 ?
                                        <NavDropdown title={m.title}>
                                            {
                                                m.items.map((n, j) => <LinkContainer to={n.to}><NavDropdown.Item key={j} to={n.to}>{n.title}</NavDropdown.Item></LinkContainer>)
                                            }
                                        </NavDropdown>
                                        :
                                        <LinkContainer to={m.to}>
                                            <Nav.Link>{m.title}</Nav.Link>
                                        </LinkContainer>
                                }
                            </>
                        </Nav.Item>)}
                    </Nav>

                    <Navbar.Text>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
                        Welcome <a href="/classes">User</a>
                    </Navbar.Text>

                </Navbar.Collapse>
            </Container>
        </Navbar>
        <LayoutContainer>
            <Outlet></Outlet>
        </LayoutContainer>
    </div>
}