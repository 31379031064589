import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import classes from '../features/classes/class.slice';
import layout from '../features/layout/layout.slice';
export const store = configureStore({
  reducer: {
    layout,
    classes
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
