import { createSlice } from "@reduxjs/toolkit";
import { ClassState } from './class-state';

const initialState: ClassState = {}

export const classSlice = createSlice({
    name: 'classes',
    initialState,
    reducers: {

    }
});

export default classSlice.reducer;